<style lang="scss">
.prprodukte {
  [class*='col-'] {
  }
  .themen-box {
    padding: 15px;
    .bild-box {
      padding: 15px 0;
      height: 300px;
      img {
        max-height: 100%;
        display: block;
        margin: 0 auto;
      }
    }
    h4 {
      font-size: 120%;
      text-align: center;
      margin-bottom: 29px;
    }
  }
}
.content-link {
  transition: all 0.2s ease;
  background: $blue-darker;
  padding: 10px 15px;
  display: inline-block;
  color: #fff !important;
  span {
    position: relative;
    top: 5px;
    display: inline-block;
    margin-right: 5px;
    transition: all 0.4s ease;
  }
  &:hover {
    background: $blue-dark;
    span {
      transform: rotate(360deg);
    }
  }
}
.erfolgbsp {
  margin-top: 20px;
  margin-bottom: 20px;
  a {
    display: block;

    position: relative;
    span {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
      width: 100%;
      text-align: center;
      background-color: rgba($color: #fff, $alpha: 0.8);
    }
  }
}

.tab-group-container {
  .title.active {
  }
  .title-container {
    @media (max-width: 768px) {
      display: block !important;
    }
  }
  .title {
    padding: 15px 20px;
    @media (max-width: 768px) {
      display: block;
      width: 100%;
      text-align: center;
    }
  }
  .inner-content {
    .leistung {
      &:before {
        content: '';
        display: block;
        margin: 20px 0;
        width: 150px;
        height: 5px;
        background: $blue;
      }
      &:after {
        content: '';
        display: block;
        margin: 20px 0;
        width: 100px;
        height: 5px;
        background: $blue;
      }
    }
  }
}
.info-txt {
  text-align: center;
  margin-top: 20px;
  font-size: 120%;
  padding: 0 50px;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    padding: 0 10px;
  }
}
.btn-big {
  display: block;
  margin: 10px 0;
  text-align: center;
  a {
    display: inline-block;
    padding: 15px 20px;
    background: $blue;
    color: #fff;
    &:hover {
      opacity: 0.9;
    }
  }
}
</style>

<template>
  <div class="content prprodukte container">
    <h1>PR-Produkte Print</h1>
    <p class="intro-txt">Werbebotschaften im Stil redaktioneller Berichterstattung: Erklärend, informativ und spannend aufbereitet. Das Team der Kleinen Zeitung-PR-Redaktion steht Ihnen mit Expertenwissen zur Seite und erstellt Ihnen Ihre Werbebotschaft aus einem Guss.</p>

    <h2>Individuelle Werbemöglichkeiten</h2>
    <p>Nutzen Sie unser umfangreiches PR-Portfolio, um Ihr Werbeziel optimal zu erreichen.</p>

    <TabGroup>
      <Tab tabid="prpdodukt-tab1" :title="'PR-Artikel im Hauptblatt'" active>
        <div class="row">
          <div class="col-lg-6 justify-content-center align-self-center">
            <img src="@/assets/img/img-content/sonderprodukte/pr-print-inserat-hauptblatt.png" alt="Inserat Hauptblatt" class="img-fluid" />
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <p>Präsentieren Sie Ihr Unternehmen im Look &amp; Feel redaktioneller Berichterstattung in einem Zeitungsressort Ihrer Wahl. Alle Werbeflächen im Hauptblatt können als PR-Artikel gebucht werden.</p>
            <router-link :to="{ name: 'Basisplatzierung' }" class="content-link"><span class="material-icons">arrow_right_alt</span> Mehr Informationen unter Basisplatzierung</router-link>
          </div>
        </div>
      </Tab>
      <Tab tabid="prpdodukt-tab2" :title="'Druckstrecke'">
        <div class="row">
          <div class="col-lg-6 justify-content-center align-self-center">
            <img src="@/assets/img/img-content/sonderprodukte/pr-print-kundenjournal.png" alt="Kundenjournal" class="img-fluid" />
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <p>Die Druckstrecke ist Bestandteil der Kleinen Zeitung und bietet Ihnen die Möglichkeit zur großflächigen und auffälligen Darstellung Ihrer Werbebotschaft.</p>
            <router-link :to="{ name: 'Druckstrecke' }" class="content-link"><span class="material-icons">arrow_right_alt</span> Mehr Informationen unter Beilagen</router-link>
          </div>
        </div>
      </Tab>
      <Tab tabid="prpdodukt-tab3" :title="'Firmenjournale und Magazine'">
        <div class="row">
          <div class="col-lg-6 justify-content-center align-self-center">
            <img src="@/assets/img/img-content/sonderprodukte/pr-produkt-firmenjournal.png" alt="Druckstrecke" class="img-fluid" />
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <p>Sie möchten Ihr eigenes Magazin oder Firmenjournal? Ob als Beilage oder als Mitdruck in der Zeitung, auf Zeitungspapier oder LWC-Papier – auf Wunsch übernehmen wir die komplette Erstellung dafür.</p>
            <router-link :to="{ name: 'Werbebeilagen' }" class="content-link"><span class="material-icons">arrow_right_alt</span> Mehr Informationen unter Beilagen</router-link>
          </div>
        </div>
      </Tab>
    </TabGroup>
    <h3>PR-Produktionskostenbeiträge</h3>
    <p>Für die Erstellung von maßgeschneiderten Inhalten für Ihre Zielgruppe werden folgende Produktionskostenbeiträge in Rechnung gestellt.</p>
    <div class="row">
      <div class="col-lg-6">
        <div class="tabelle-box">
          <div class="headline">
            Produktionsbeitrag im Hauptblatt
          </div>
          <div class="row head-row">
            <div class="col-6 text-align-left">Leistung</div>
            <div class="col-6 text-align-right">Tarif</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left">bis 1/1 Seite</div>
            <div class="col-6 text-align-right">225,00</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left">bis 1/2 Seite</div>
            <div class="col-6 text-align-right">125,00</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left">bis 1/4 Seite</div>
            <div class="col-6 text-align-right">65,00</div>
          </div>
          <div class="info-txt-tabelle">Zusätzlich zum Inseratentarif</div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="tabelle-box">
          <div class="headline">
            Produktionskostenbeitrag Journale &amp; Magazine
          </div>
          <div class="row head-row">
            <div class="col-6 text-align-left">Leistung</div>
            <div class="col-6 text-align-right">Tarif pro Seite</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left">ohne Fotos</div>
            <div class="col-6 text-align-right">225,00</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left">inkl. Fotoservice **</div>
            <div class="col-6 text-align-right">450,00</div>
          </div>
          <div class="info-txt-tabelle">Zusätzlich zu den Druck- und Beilegekosten. <router-link :to="{ name: 'Werbebeilagen' }">Hier zu mehr Informationen. </router-link></div>
        </div>
      </div>
    </div>
    <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 5 % Werbeabgabe und 20 % Umsatzsteuer.</div>
    <div class="download-pdf">
      <div class="line"><span>DOWNLOAD</span></div>
      <a href="../../pdf/prprodukte/PR-Produkte.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Tarif PR-Produkte Print
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>310 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>

    <Modal></Modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';

export default defineComponent({
  name: 'PRProdukteDigital',
  components: {
    TabGroup,
    Tab,
  },
});
</script>
